import React from 'react';
import styled from 'styled-components';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import { Spinner } from 'react-mdl';

const LoadingForm = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : `100%`)};
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : `100`)}%;
  padding: ${(props) => (props.isWeb ? '39.5% 0' : '')};
`;

const PopupLoading = ({ height, paddingTop, isWeb = false }) => {
  return (
    <>
      <LoadingForm height={height} paddingTop={paddingTop} isWeb={isWeb}>
        <Spinner />
      </LoadingForm>
    </>
  );
};

export default PopupLoading;
