import L from 'leaflet';

const MarkerIcon = (ID, isClicked, width = 50, height = 45) => {
  return new L.Icon({
    iconUrl: require(isClicked
      ? 'assets/map_marker_on.svg'
      : 'assets/map_marker_off.svg'),
    iconRetinaUrl: require(isClicked
      ? 'assets/map_marker_on.svg'
      : 'assets/map_marker_off.svg'),
    iconAnchor: [20, 55],
    popupAnchor: [5, -44],
    iconSize: [width, height],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    className: `leaflet-custom-marker-${isClicked ? 'on' : 'off'}-${ID}`,
  });
};

export default MarkerIcon;
