import axios from 'axios';
// axios.defaults.withCredentials = true;

// 로컬스토리지 값 { } 가져오기
const prefix = localStorage.getItem('keyPrefix');
const user = localStorage.getItem('userName');
const idToken = localStorage.getItem(`${prefix}.${user}.idToken`);

const header = {
  Authorization: `Bearer ${idToken}`,
  'Content-Type': 'application/json',
};
const baseURL = (() => {
  if (process.env.NODE_ENV === 'development')
    return 'https://wuqhn9t75a.execute-api.ap-northeast-2.amazonaws.com/';
  if (process.env.APP_ENV === 'server' && process.env.LOCAL === 'true') {
    return 'https://wuqhn9t75a.execute-api.ap-northeast-2.amazonaws.com/';
  }
  return 'https://wuqhn9t75a.execute-api.ap-northeast-2.amazonaws.com/';
})();

// jwtToken 인증 헤더 추가
const HttpClient = axios.create({
  baseURL,
  headers: header,
  // withCredentials: true,
});

if (process.env.APP_ENV === 'server') {
  HttpClient.defaults.timeout = 5000;
}

export default HttpClient;
