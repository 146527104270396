import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #983f42;
  display: inline-block;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

export default function ({
  width = 6,
  height = 6,
  marginRight = 10,
  marginBottom = 3,
}) {
  return (
    <Circle
      width={width}
      height={height}
      marginRight={marginRight}
      marginBottom={marginBottom}
    />
  );
}
