import React, { useContext } from 'react';
// import Store from 'store';

const StyledInput = ({ context, StyledInput }) => {
  const { onChange, handleSearch } = useContext(context);
  return (
    <>
      <StyledInput onChange={onChange} onKeyPress={handleSearch} />
    </>
  );
};

export default StyledInput;
