import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const PagingArea = styled.div`
  display: flex;
  width: ${props => `${props.width}`};
  height: ${props => `${props.height}`};
  padding-top: ${props => (`${props.mobile}` ? `1px` : `10px`)};
`;

// color 바꿀 것
const PageStatus = styled.div`
  font-size: ${props => (`${props.mobile}` ? `0.6rem` : `0.8rem`)};
  bold: 0;
  color: #cecece;
`;

const PaginationForm = styled.div`
  margin: 0 auto;
`;

const ChevronButton = styled.span`
  margin-left: ${props => props.left}px;
  margin-right: ${props => props.right}px;
  cursor: pointer;
`;

const PageButton = styled.span`
  display: inline-block;
  padding: ${props =>
    `${props.mobile}` ? `5px 7px` : `5px ${props.paddingPagesDisplayed}px`};
  cursor: pointer;
  background-color: ${props => (props.selected ? '#cecece' : '#fff')};
  border-radius: 3px;
  font-size: 0.8rem;
  margin: 0 5px;
`;

const Pagination = ({
  mobile,
  totalPageCount,
  currentPage,
  currentDisplayPage,
  pageRangeDisplayed,
  contsDisplayCount,
  paddingPagesDisplayed,
  onPageChange,
  width,
  height,
  leftIcon = <FontAwesomeIcon icon={faChevronLeft} />,
  rightIcon = <FontAwesomeIcon icon={faChevronRight} />,
}) => {
  // 묶음 페이지 개수
  const totalPageDisplay = Math.ceil(totalPageCount / contsDisplayCount);
  return (
    <PagingArea width={width} height={height}>
      <PageStatus>{`Page ${currentPage} of ${totalPageDisplay}`}</PageStatus>
      <PaginationForm>
        {currentDisplayPage > 1 ? (
          <ChevronButton
            className="prev"
            right={15}
            onClick={e => onPageChange(e)}
          >
            {leftIcon}
          </ChevronButton>
        ) : (
          ''
        )}
        {Array.from(
          new Array(
            totalPageDisplay > currentDisplayPage * pageRangeDisplayed
              ? pageRangeDisplayed
              : totalPageDisplay > pageRangeDisplayed
              ? totalPageDisplay - (currentDisplayPage - 1) * pageRangeDisplayed
              : totalPageDisplay,
          ),
        ).map((_, i) => {
          return (
            <PageButton
              key={i}
              paddingPagesDisplayed={paddingPagesDisplayed}
              onClick={e => onPageChange(e)}
              selected={
                currentDisplayPage * pageRangeDisplayed - 4 + i === currentPage
              }
            >
              {currentDisplayPage * pageRangeDisplayed - 4 + i}
            </PageButton>
          );
        })}
        {currentDisplayPage * pageRangeDisplayed < totalPageDisplay ? (
          <ChevronButton
            className="next"
            left={15}
            onClick={e => onPageChange(e)}
          >
            {rightIcon}
          </ChevronButton>
        ) : (
          ''
        )}
      </PaginationForm>
    </PagingArea>
  );
};

Pagination.propTypes = {
  totalPageCount: PropTypes.number.isRequired, // 페이지 전체 개수
  pageRangeDisplayed: PropTypes.number.isRequired, // 하단에 보여지는 페이지 개수
  contsDisplayCount: PropTypes.number.isRequired, // Carousel에 보여지는 장비 개수
  paddingPagesDisplayed: PropTypes.number.isRequired, // 페이지 버튼 간격
  currentDisplayPage: PropTypes.number.isRequired, // 현재 보여지는 묶음 페이지 번호 ( ex: 하단에 1 ~ 5 페이지가 보여질 때 1 )
  onPageChange: PropTypes.func, // 페이지 버튼 클릭 이벤트
  width: PropTypes.string,
  height: PropTypes.string,
  leftIcon: PropTypes.node, // 좌측 화살표 이미지
  rightIcon: PropTypes.node, // 우측 화살표 이미지
};
export default Pagination;
