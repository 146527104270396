import React from 'react';
import styled, { keyframes } from 'styled-components';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import { Spinner } from 'react-mdl';

const LoadingForm = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : `100%`)};
  position: fixed;
  z-index: 2021;
  top: 50%;
  left: 50%;
`;
const LoadingImg = styled.img.attrs({
  src: '/img/ajax_loader.gif',
  alt: 'Loading...',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const rotateLoading = keyframes`
  0%  {
  transform: rotate(0deg);
  
   }
  100% {
  transform: rotate(360deg);
  }
`;

const loadingTextOpacity = keyframes`
  0%  {opacity: 0}
  20% {opacity: 0}
  50% {opacity: 1}
  100%{opacity: 0}
`;

const LoadingContainer = styled.div`
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-color: transparent #e45635 transparent #e45635;
  animation: ${rotateLoading} 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
`;

const LoadingText = styled.div`
  animation: ${loadingTextOpacity} 2s linear 0s infinite normal;
  color: #e45635;
  font-family: 'Helvetica Neue, ' Helvetica ', ' 'arial';
  font-size: 10px;
  font-weight: bold;
  margin-top: 45px;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100px;
`;

const Loading = ({ height }) => {
  return (
    <>
      <LoadingForm height={height}>
        {/*<Spinner />*/}
        <LoadingContainer />
        <LoadingText>loading</LoadingText>
      </LoadingForm>
    </>
  );
};

export default Loading;
