import { Http } from 'Common/utils';

/**
 * 비밀번호 찾기 1단계
 * @param {String} id
 * @return {String "확인 코드 발송"}
 */
export const getConfirmationCode = (userId) => {
  return Http.post('manage/forgotPassword', {
    id: userId,
  });
};

/**
 * 비밀번호 찾기 2단계 ( 비밀번호 수정 )
 * @param {String} id
 * @param {String} newPassword
 * @param {String} confirmationCode
 * @return {Array}
 */
export const changeNewPassword = (userInfo) => {
  return Http.put('manage/forgotPasswordChange', userInfo);
};

/**
 * 사용자 정보 수정( 내 정보 수정 )
 * @param {String} id
 * @param {String} grade
 * @param {String} name
 * @param {String} address
 * @param {String} phoneNumber
 * @param {String} email
 * @method PUT
 * @return {String}
 */

export const updateMyInfo = (userInfo) => {
  return Http.put('manage/user', userInfo);
};

/**
 * 사용자 정보 수정(비밀번호 변경하기 )
 * @param {String} accessToken
 * @param {String} previousPassword
 * @param {String} newPassword
 * @method PUT
 * @return {String}
 */
export const updateMyPassword = (passwordInfo) => {
  return Http.put('manage/changePassword', passwordInfo);
};

/**
 * ID 찾기
 * @param {String} e-mail 이나 전화번호
 * @method post
 * @return {Array}
 */
export const findForgotID = (params) => {
  return Http.post('manage/forgotID', params);
};
