import { useState, useCallback } from 'react';

const useInput = (initialValue, validator) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    event => {
      const {
        target: { value },
      } = event;
      let willUpdate = true;
      if (typeof validator === 'function') {
        willUpdate = validator(value);
      }
      if (willUpdate) {
        setValue(value);
      }
    },
    [validator],
  );

  return [value, onChange];
};

export default useInput;
