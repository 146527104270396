import React, { useEffect, useState } from 'react';

const NotFound = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setMessage('Not Found');
    }, 1000);
  }, []);

  return <div>{message}</div>;
};

export default NotFound;
