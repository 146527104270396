import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import awsConfig from './Common/utils/awsConfig';
import Amplify from 'aws-amplify';
import './Lang';
import Loadable from 'react-loadable';
import { Loading } from 'Common/Components';

Amplify.configure({
  identityPoolRegion: awsConfig.cognito.RESION,
  userPoolId: awsConfig.cognito.USER_POOL_ID,
  userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
});

export const Root = Loadable({
  loader: () => import('./Root'),
  loading: Loading,
});

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById('container'),
);
