import React, { useState, useEffect } from 'react';
import { Popup, TreeView, DropDownBox } from 'devextreme-react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { isBrowser } from 'react-device-detect';
import { isEmpty } from '../utils';
import { control } from 'leaflet';
import { Bar } from 'react-chartjs-2';
const CloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexDiv = styled.div`
  display: block;
`;

const CopyBtn = styled.div`
  border: 1px solid #ccc;
  background-color: ${(props) =>
    props.isControlId !== '' && props.isDropdow.length !== 0
      ? '#b54549'
      : '#ccc'};
  padding: 10px 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
`;

const renderTreeViewItem = (item, page = 'schedule', controlId) => {
  return (
    <>
      {page !== 'userzone' && item.items.length === 0 ? (
        <>
          <FontAwesomeIcon
            icon={faClock}
            style={{
              fontSize: '15px',
              color: item.PointEnable ? 'red' : '#000',
            }}
          />
          <span style={{ marginLeft: '2px' }}>{item.text}</span>
        </>
      ) : (
        `${item.text}`
      )}
    </>
  );
};

const TreeViewComponent = ({
  locationTreeRef,
  controlId,
  asyncControlCopy,
  setIsPopup,
  page,
}) => {
  const [treeViewItems, setTreeViewItems] = useState([]);
  const [selectedNode, setSelectedNode] = useState([]);
  const treeviewRef = React.useRef(null);

  const itemSelection = (e) => {
    const selectedData = e.component
      .getSelectedNodes()
      .map((node) => {
        if (
          controlId.indexOf('SetTemp') !== -1 &&
          node.text.indexOf('SetTemp')
        ) {
          if (!node.items.length) {
            if (page !== 'userzone') {
              if (
                treeviewRef.current !== null &&
                node.hasOwnProperty('disabled') &&
                node.disabled
              ) {
                // treeviewRef.current.instance.unselectItem(node.itemData);
              }
              return node.itemData.text;
            } else {
              return `${node.parent.parent.text}>${node.parent.text}>${node.text}`;
            }
          }
        }
      })
      .filter((v) => v);
    setSelectedNode(selectedData);
  };

  useEffect(() => {
    setTreeViewItems(locationTreeRef.concat());
  }, [locationTreeRef, setTreeViewItems]);

  return (
    <>
      <FlexDiv>
        <div>
          <div style={{ padding: '10px 0' }}>원본 제어스케줄 : {controlId}</div>
          <FlexDiv>
            <div style={{ marginBottom: '10px', paddingRight: '5px' }}>
              대상 제어스케줄 :
            </div>
            <DropDownBox
              style={{ flex: 1 }}
              valueExpr={'id'}
              displayExpr={'text'}
              placeholder={'Select'}
              showClearButton={false}
              value={selectedNode}
              contentRender={() => (
                <TreeView
                  ref={treeviewRef}
                  style={{ flex: 1 }}
                  id="treeview"
                  height={320}
                  items={treeViewItems}
                  // items={locationTreeRef}
                  selectByClick={true}
                  showCheckBoxesMode={'normal'}
                  selectionMode={'multiple'}
                  // onSelectionChanged={itemSelection}
                  onInitialized={(e) => {
                    e.component.unselectAll();
                  }}
                  itemRender={(item) =>
                    renderTreeViewItem(item, page, controlId)
                  }
                  onItemSelectionChanged={itemSelection}
                />
              )}
            />
          </FlexDiv>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: '10px',
          }}
        >
          <CopyBtn
            onClick={() => asyncControlCopy(selectedNode, setIsPopup)}
            isControlId={controlId}
            isDropdow={selectedNode}
          >
            복제
          </CopyBtn>
        </div>
      </FlexDiv>
    </>
  );
};

const ControlPopup = ({
  isPopup,
  setIsPopup,
  locationTreeRef,
  controlId,
  asyncControlCopy,
  page,
}) => {
  return (
    <>
      <Popup
        visible={isPopup}
        width={isBrowser ? '600' : '100%'}
        height={600}
        closeOnOutsideClick={false}
        showCloseButton={false}
        titleComponent={() => (
          <CloseDiv>
            <img
              style={{ cursor: 'pointer' }}
              src="/img/icon_round_close.png"
              onClick={() => {
                setIsPopup(false);
              }}
            />
          </CloseDiv>
        )}
        contentComponent={() => (
          <>
            <TreeViewComponent
              locationTreeRef={locationTreeRef}
              controlId={controlId}
              asyncControlCopy={asyncControlCopy}
              setIsPopup={setIsPopup}
              page={page}
            />
          </>
        )}
      />
    </>
  );
};

export default ControlPopup;
