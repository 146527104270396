/*global kakao*/
import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'devextreme-react';
import { getUserInfo } from '../../api/engineer';
import DaumPostcode from 'react-daum-postcode';
import { updateMyInfo, updateMyPassword } from '../../api/login';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    props.isMobile &&
    `
  height: 100%;
  ;`}
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;

  > span {
    margin-right: 5px;
    ${(props) => props.isMobile && `font-size:0.8em`};
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

const FindPwBtn = styled.div`
  width: 120px;
  height: 35px;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #983f42;
  background-color: #983f42;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const FindInput = styled.input`
  width: ${(props) =>
    props.isMobile ? `calc(100% - 90px)` : `calc(100% - 120px)`};
  min-width: ${(props) =>
    props.isMobile ? `calc(100% - 90px)` : `calc(100% - 120px)`};

  height: ${(props) => (props.isMobile ? `25px` : `35px`)};
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  color: #000;
  background-color: transparent;
  padding-left: 10px;
  position: relative;
  ${(props) => props.isMobile && `font-size:0.8em`};
  :focus {
    border: 1px solid #983f42;
    outline: none;
    background-color: #f7f7f7;
    border-radius: 5px;
    color: #983f42;
    font-weight: bold;
  }
`;

const SearchImg = styled.img.attrs({
  src: '/img/icon_search.png',
  alt: 'Search Image',
})`
  width: 25px;
  height: 20px;
  padding-left: 5px;
  position: absolute;
  right: 30px;
  cursor: pointer;
`;

const InfoComponent = ({ setIsPopup, isPopup, isMobile }) => {
  const { userName, userGrade, accessToken } = localStorage;
  const [userInfo, setUserInfo] = useState({
    Name: '',
    Email: '',
    Address: '',
    PhoneNumber: '',
  });
  const [pwInfo, setPwInfo] = useState({
    accessToken: accessToken,
    previousPassword: '',
    newPassword: '',
    newPasswordCheck: '',
  });
  const [isModify, setIsModify] = useState(false);
  const [isDaumMap, setIsDaumMap] = useState(false);
  let geoRef = useRef(null);

  const getInfo = async () => {
    try {
      const { data } = await getUserInfo(userGrade, userName);
      console.log('data', data);
      setUserInfo({
        name: data.Name,
        email: data.Email,
        address: data.Address,
        phoneNumber: data.PhoneNumber,
      });
    } catch (err) {
      console.log('getInfo ERROR', err);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const modifyInfoCall = async () => {
    try {
      if (pwInfo.newPassword !== '' && pwInfo.previousPassword !== '') {
        const passwordPattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

        if (pwInfo.newPasswordCheck === '') {
          alert('비밀번호를 재 입력 해주세요.');
          return false;
        }
        if (pwInfo.newPassword !== pwInfo.newPasswordCheck) {
          alert('비밀번호와 같은 값을 입력 해주세요');
          return false;
        }
        if (
          !passwordPattern.test(pwInfo.newPassword) &&
          !passwordPattern.test(pwInfo.previousPassword)
        ) {
          alert(
            '비밀번호는 알파벳 대소문자, 숫자, 특수문자 포함 8자 이상 입력 해주세요.',
          );
          return false;
        }

        const { data } = await updateMyInfo({
          ...userInfo,
          id: userName,
          grade: userGrade,
        });

        const { data: pwData } = await updateMyPassword(pwInfo);
        alert(data);
        alert(pwData);
      } else {
        const { data } = await updateMyInfo({
          ...userInfo,
          id: userName,
          grade: userGrade,
        });

        alert(data);
      }

      setIsPopup(false);
    } catch (error) {
      console.log('modifyInfoCall Error=====>', error);
    }
  };

  return (
    <>
      {isDaumMap ? (
        <>
          <div>
            <DaumPostcode
              autoResize={true}
              onComplete={(e) => {
                setUserInfo((prev) => ({
                  ...prev,
                  address: e.roadAddress,
                }));
                setIsDaumMap(false);
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                setIsDaumMap(false);
              }}
            >
              <FindPwBtn>닫기</FindPwBtn>
            </div>
          </div>
        </>
      ) : (
        <Wrapper isMobile={isMobile}>
          <FlexDiv isMobile={isMobile}>
            <span>아이디 :</span>
            <FindInput value={userName} isMobile={isMobile} readOnly />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>등급 :</span>
            <FindInput value={userGrade} isMobile={isMobile} readOnly />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>이름 :</span>
            <FindInput
              value={userInfo.name}
              readOnly={!isModify}
              isMobile={isMobile}
              onChange={(e) => {
                let value = e.target.value;
                setUserInfo((prev) => ({ ...prev, name: value }));
              }}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>이메일 :</span>
            <FindInput
              value={userInfo.email}
              readOnly={!isModify}
              isMobile={isMobile}
              onChange={(e) => {
                let value = e.target.value;
                setUserInfo((prev) => ({ ...prev, email: value }));
              }}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>주소 :</span>
            <FindInput
              value={userInfo.address}
              isMobile={isMobile}
              readOnly={!isModify}
              onChange={(e) => {
                let value = e.target.value;
                setUserInfo((prev) => ({ ...prev, address: value }));
              }}
            />
            <SearchImg
              onClick={() => {
                if (isModify) {
                  setIsDaumMap(true);
                }
              }}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>연락처 :</span>
            <FindInput
              value={userInfo.phoneNumber}
              readOnly={!isModify}
              isMobile={isMobile}
              placeholder={'+821012345678'}
              maxLength={14}
              onChange={(e) => {
                let value = e.target.value;
                setUserInfo((prev) => ({ ...prev, phoneNumber: value }));
              }}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>기존 비밀번호 :</span>
            <FindInput
              readOnly={!isModify}
              isMobile={isMobile}
              onChange={(e) => {
                let value = e.target.value;
                setPwInfo((prev) => ({ ...prev, previousPassword: value }));
              }}
              type={'password'}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>비밀번호 :</span>
            <FindInput
              readOnly={!isModify}
              isMobile={isMobile}
              onChange={(e) => {
                let value = e.target.value;
                setPwInfo((prev) => ({ ...prev, newPassword: value }));
              }}
              type={'password'}
            />
          </FlexDiv>
          <FlexDiv isMobile={isMobile}>
            <span>비밀번호 확인 :</span>
            <FindInput
              readOnly={!isModify}
              isMobile={isMobile}
              onChange={(e) => {
                let value = e.target.value;
                setPwInfo((prev) => ({ ...prev, newPasswordCheck: value }));
              }}
              type={'password'}
            />
          </FlexDiv>
          <BtnWrapper>
            {isModify ? (
              <FindPwBtn
                onClick={() => {
                  modifyInfoCall();
                }}
              >
                수정하기
              </FindPwBtn>
            ) : (
              <>
                <FindPwBtn
                  onClick={() => {
                    setIsModify(true);
                  }}
                >
                  수정
                </FindPwBtn>
              </>
            )}
          </BtnWrapper>
        </Wrapper>
      )}
    </>
  );
};

const InfoPopup = ({ isPopup, setIsPopup, isMobile }) => {
  return (
    <>
      <Popup
        visible={isPopup}
        width={isMobile ? 300 : 600}
        height={isMobile ? 450 : 600}
        closeOnOutsideClick={false}
        showCloseButton={false}
        dragEnabled={!!isMobile}
        titleComponent={() => (
          <CloseDiv>
            <span>* 상세정보</span>
            <img
              style={{ cursor: 'pointer' }}
              src="/img/icon_round_close.png"
              onClick={() => {
                setIsPopup(false);
              }}
            />
          </CloseDiv>
        )}
        contentComponent={() => (
          <InfoComponent
            isPopup={isPopup}
            setIsPopup={setIsPopup}
            isMobile={isMobile}
          />
        )}
      />
    </>
  );
};

export default InfoPopup;
