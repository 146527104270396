/*
 *
 * CommonFnc.js - 공통 함수 유틸
 *
 * @author Sunhyeok.Choe
 * @since 21.04.29
 * @version  1.0
 *
 *
 * == Modification Information ==
 * Date         Modifier        Comment
 * ==============================================================
 * 21.??.?? 언제 넣었을까~  Sunhyeok.Choe.  함수 formatNumber 추가
 * 21.09.08     Sunhyeok.Choe.  함수 nameof 추가
 * ==============================================================
 *
 *
 */

export function isEmpty(value) {
  let result = true;
  if (value instanceof Object) {
    if (value !== undefined && Object.keys(value).length > 0) {
      result = false;
    }
  } else if (value instanceof Array) {
    if (value !== undefined && value.length > 0) {
      result = false;
    }
  } else {
    if (value) {
      result = false;
    }
  }

  return result;
}

export function recursiveSearchNode(tree, key, targetNode) {
  if (isEmpty(tree)) return;

  let node = tree.find((branch) => branch[key] === targetNode);
  if (node) return node;

  const nextLevelNodes = tree.flatMap((n) => n.items).filter((c) => c);

  return recursiveSearchNode(nextLevelNodes, key, targetNode);
}

export const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
}).format;

/**
 * 변수명 문자열화.
 * 
 * @params {Object} variable 문자열화 시킬 변수
 * @returns {String} 문자열화된 변수명
 */
export const nameof = variable => Object.keys(variable)[0];
