import { Http } from 'Common/utils';
/**
 *
 *  Customer 등급 사용자 생성
 *  @method POST
 *  @payload {
 *             id: String,
 *             password: String,
 *             name: String,
 *             grade: String,
 *             email : String,
 *             enabled:String,
 *             address:String,
 *             phoneNumber: String
 *             expiryDate : String,
 *             companyName: String ,
 *             companyCode: String,
 *             contractPeriod:String
 *           }
 *  @return { CustomerID: String, Username: String }
 *
 */
export const createCustomerUser = (selectedUserInfo) => {
  return Http.post(`manage/user`, selectedUserInfo);
};

/**
 *
 *  Group/Building Manager 생성
 *  @method POST
 *  @param  {String} customerId
 *  @payload {
 *             id: String,
 *             name: String,
 *             grade: String,
 *             email : String,
 *             enabled:String,
 *             address:String,
 *             phoneNumber: String
 *             expiryDate: String,
 *             password: String,
 *            }
 *  @return {}
 *
 */
export const createManagerUser = (selectedUserInfo) => {
  return Http.post(`manage/user`, selectedUserInfo);
};

/**
 *
 *  권한 등급 별 사용자 목록
 *  @method GET
 *  @return {Array<{ Username: String, Name: String, Grade: String ...}>}
 *
 */
export const getUserList = () => {
  return Http.get(`manage/users`);
};

/**
 * 사용자 상세정보 읽기 
 * (2020-05-24 수정 )
 *  @method GET
 *  @param {String} grade
 *  @param {String} id
 *  @return  {
    Address: String
    Grade: String
    ExpiryDate: String,
    UserNumber: String,
    ContractPeriod": String,
    CompanyName": String,
    Email: String,
    CompanyCode": String,
    Managers: {
        "BuildingManager": [
            {
                "id" :String 
            }
        ]
    },
    PhoneNumber": String,
    RootID: String,
    ID: String,
    Buildings: [
        "B111",
        "B222"
    ],
    "Activate": String,
    "Name": String
}
 * 
 */
export const getUserInfo = (grade, id) => {
  return Http.get(`manage/user?grade=${grade}&id=${id}`);
};

/**
 * 사용자가 관리하는 건물 목록
    @method GET
    @param { String } customerId
 *  @return  { buildings: Array<Building> }
 *  
 */
export const getBuildingList = (customerId) => {
  return Http.get(`customers/${customerId}/secure/buildings`);
};

/**
 * 사용자 상세정보 수정
 *  @method PUT
 *  @payload {
 *              id: String,
 *              name: String,
 *              email: String,
 *              Customer_ID: String,
 *              buildings: String,
 *              graphics: String,
 *              enabled: String,
 *              expiryDate: String,
 *              companyName: String,
 *              companyCode: String,
 *              contractPeriod: String,
 *              grade: String,
 *            }
 *  @return {}
 *
 */
export const updateUserInfo = (userInfo) => {
  return Http.put(`/manage/user`, userInfo);
};

/**
 * 사용자 활성화/비활성화 수정
 *  @method PUT
 *  @payload {
 *              id: String,
 *              grade: String,
 *              activate: boolean
 *            }
 *  @return {}
 *
 */
export const updateUserState = (userInfo) => {
  return Http.put(`/manage/userState`, userInfo);
};

/**
 * 사용자 삭제
 *  @method DELETE
 *  @data {
 *              id: String,
 *              grade: String,
 *              userNumber: String,
 *              deleteTables: boolean
 *        }
 *  @return  {
 *              res: Object
 *           }
 *
 */
export const deleteUser = (id, grade, userNumber) => {
  return Http.delete(`/manage/user`, {
    data: {
      id: id,
      grade: grade,
      customerNumber: userNumber,
      deleteTables: 'true',
    },
  });
};
/**
 *  Region ID 생성
 *  @method POST
 *  @param {String} customerId
 *  @payload {
 *             RegionName: String,
 *           },
 *  @return  { RegionName: String, RegionID: String }
 *
 */

export const createResionID = (customerId, regionInfo) => {
  return Http.post(`customers/${customerId}/region`, regionInfo);
};

/**
 *  // Customer 등급 사용자 목록
 * (2020-05-24 수정) 모든 고객 조회 [Engineer 이상 권한 필요]
 *  @method GET
 *  @return  { Array[Object]
 * Object ===> {  id: String,
 *                name: String,
 *                companyName: String,
 *                customerNumber: String}
 * }
 *
 */
export const getCustomerList = () => {
  return Http.get(`manage/customers`);
};

/**
 *  Customer에 속한 건물 목록
 *  (2020-05-24 수정)
 *  @method GET
 *  @param {String} customerNumber
 *  @return  {
 *            Buildings:Array<Tree>   Tree구조
 *          }
 */

export const getCustomerBuildingList = (customerNumber) => {
  return Http.get(
    `manage/customerBuildingsByGroup?customerNumber=${customerNumber}`,
  );
};

/**
 *  관리 빌딩 목록
 *  @method GET
 *  @param {String} grade
 *          {String} id
 *  @return  {
 *            Buildings:Array<Tree>   Tree구조
 *          }
 */

export const getManageBuildingList = (grade, id) => {
  return Http.get(`manage/getManageBuildingList?id=${id}&grade=${grade}`);
};

/**
 * 관리 고객 목록 (Engineer)
 *  @method GET
 *  @param {String} grade
 *          {String} id
 *  @return  {
 *            Buildings:Array<Tree>   Tree구조
 *          }
 */

export const getManageCustomerList = (grade, id) => {
  return Http.get(`manage/getManageCustomersList?id=${id}&grade=${grade}`);
};

/**
 *  건물의 상세정보 읽기
 *  (수정)
 *  @method GET
 *  @param {String} customerNumber
 *  @param {String} buildingNumber
 *  @return  {
 *               Address: {
 *                   groundNumber: {String},
 *                   roadAddress: {String}
 *               },
 *               CustomerNumber: {String},
 *               Longitude: {String}8,
 *               Electricity: {Array},
 *               Latitude: {String},
 *               BuildingNumber": {String},
 *               Gas: {Array},
 *               AlarmSubscribers: {Array},
 *               Group: {String},
 *               BuildingName: {String},
 *               MiddlewareMQTT:{Array}
 *           }
 *
 */

export const getBuildingInfo = (customerNumber, buildingNumber) => {
  return Http.get(
    `manage/building?customerNumber=${customerNumber}&buildingNumber=${buildingNumber}`,
  );
};

/**
 *  빌딩 생성 
 *  (수정)
 *  @method POST
 *  @payload {
 *              customerID:{String},
                customerNumber:{String},
                buildingName:{String},
                address:{
                  roadAddress:{String},
                  groundNumber:{String}
                },
                latitude:{String},
                longitude:{String},
                contractPower:{String},
                objectPower:{String},
                electricRate:{String}
 *           }
 *  @return   { }
 *
 */
export const createBuilding = (buildingInfo) => {
  return Http.post(`/manage/building`, buildingInfo);
};

/**
 *  건물의 상세정보 수정 
 *  (수정 )
 *  @method PUT
 *  @payload {
        customerNumber : {String}
        buildingNumber :{String},
        buildingName :{String},
        address:{ roadName : {String}, groundNumber:{String} },
        latitude: {String},
        longitude :{String}
      }
 *  @return   { }
 *
 */
export const updateBuilding = (payload) => {
  return Http.put(`/manage/building`, payload);
};

/**
 *  건물 삭제 (수정 )
 *  @method DELETE
 *  @param {String}  customerNumber
 *  @param {String}  buildingNumber
 *  @param {String}  deleteTables
 *  @data {
 *           customerNumber :String,
 *           buildingNumber : String,
 *           deleteTables : String
 *        }
 *  @return   { String }
 *
 */
export const deleteBuilding = (payload) => {
  return Http.delete(`manage/building`, {
    data: payload,
  });
};

/**
 *  지역 삭제
 *  @method DELETE
 *  @param {String}  customerId
 *  @data {
 *           RegionID : String
 *        }
 *  @return   { CustomerID :String , RegionID : String }
 *
 */
export const deleteRegion = (customerId, regionId) => {
  return Http.delete(`customers/${customerId}/region`, {
    data: { RegionID: regionId },
  });
};

// /** 
//  * 디바이스 생성(추가)
//  * @method POST
//  * @param {string} customerNumber 
//  * @param {string} buildingNumber 
//  * @payload {
//  *          MiddleWareName: {String}
//  * } 
//  *  @returns {}
//  */
//  export const createmiddleWare = (customerNumber, buildingNumber, middleWareName) => {
//   return Http.post(`/manage/middleWare`, customerNumber, buildingNumber, middleWareName);
// };

// /** 
//  * 디바이스 수정
//  * @method PUT
//  * @payload {
//  *        customerNumber : {string}
//  *        buildingNumber : {string}
//  *        middleWareID : {string},
//  *        middleWareName : {string} 
//  * @returns { MiddleWareName: {String}, MiddleWareID: {String}
//  * }
//  */
// export const updatemiddleWare = (customerNumber, buildingNumber, middleWareID, middleWareName) => {
//   return Http.put(`/manage/middleWare`, customerNumber, buildingNumber, middleWareID, middleWareName);
// };

// /**
//  * 디바이스 삭제
//  * @method DELETE
//  * @param {string} _customerNumber 
//  * @param {string} _buildingNumber 
//  * @data {
//  *           customerNumber :String,
//  *           buildingNumber : String,
//  *           deleteTables : string         
//  *         }
//  * @return   {String }
//  */
//  export const deletemiddleWare = (_customerNumber, _buildingNumber, middleWareID) => {
//   return Http.delete(`manage/middleWare`,{
//     data: {
//           middleWareID: middleWareID,
//           deleteTables: 'true',
//     },
//   });
// };

/**
 *  그래픽 페이지 업로드
 *  @method POST
 *  @param {String}  customerId
 *  @param {String}  buildingId
 *  @payload {
 *            GraphicName: String,
 *            GraphicContent: String (base64)
 *           }
 *
 */
export const uploadGraphicsFile = (customerId, buildingId, graphicsData) => {
  return Http.post(
    `customers/${customerId}/buildings/${buildingId}/graphic`,
    graphicsData,
  );
};

/**
 *  건물의 그래픽 페이지 트리 출력
 *  @method GET
 *  @param { String }  customerId
 *  @param { String }  buildingId
 *  @return { Graphics: Array<Tree> } Tree 구조
 *
 */
export const getGraphicsList = (customerId, buildingId) => {
  return Http.get(
    `/Analisis/graphics?customerID=${customerId}&buildingID=${buildingId}`,
  );
};

/**
 *  모든 건물 리스트 출력
 *  @method GET
 *  @return { Buildings: Array<BuildingInfo> } Tree 구조
 *
 */
export const getAllBuildingList = () => {
  return Http.get(`main/buildings`);
};

/**
 * ( 수정 )
 *  빌딩 그룹핑
 *  @method PUT
 *  @param {Object} {customerNumber:{String}, ...group:{Object}}
 *  @return { Buildings: Array<BuildingInfo> } Tree 구조
 *
 */
export const buildingsGrouping = (groupInfo) => {
  return Http.put(`manage/buildingsGrouping`, groupInfo);
};

/**
 * [Engineer] 전용 빌딩목록 불러오기
 */
export const getEngineerBulidingList = () => {
  return Http.get(`manage/EngineerBuildingList`);
};

/**
 * [GroupManager, BuildingManager] 전용 빌딩목록 불러오기
 * @param {String} customerNumber
 */
export const getManagerBuildingList = (customerNumber) => {
  return Http.get(`manage/BuildingList`, {
    params: {
      customerNumber: customerNumber,
    },
  });
};

/**
 * [GeneralUser] 전용 빌딩목록 불러오기
 */
export const getGeneralUserBuildingList = (customerNumber, buildingNumber) => {
  return Http.get(`manage/UserBuildingList`, {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
    },
  });
};

/**
 * customerNumbers 불러오기
 */
export const getCustomerNumbers = () => {
  return Http.get(`manage/ManagerCustomers`);
};

/**
 * customerNumber에 속한 building목록 리턴
 * @param {String} customerNumber
 */
export const getCustomerBuildings = (customerNumber) => {
  return Http.get(`manage/CustomerBuildingList`, {
    params: {
      customerNumber: customerNumber,
    },
  });
};

/**
 * 관리자(Customer, GroupManager, BuildingManager) 메뉴 세팅
 * @param {Object} menu
 */
export const modifyManagerMenu = (menu) => {
  return Http.post(`manage/ManagerAppSetting`, menu);
};

/**
 * 관리자 메뉴 가져오기
 * @param {String} customerNumber
 * @param {String} buildingNumber
 */
export const getManagerMenu = (customerNumber, buildingNumber) => {
  return Http.get(`manage/ManagerAppSetting`, {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
    },
  });
};

/**
 * 입주사(GeneralUser) 메뉴 세팅
 * @param {Object} menu
 */
export const modifyAppUserMenu = (menu) => {
  return Http.post(`manage/UserAppSetting`, menu);
};

/**
 * 입주사 메뉴 가져오기
 * @param {String} customerNumber
 * @param {String} buildingNumber
 */
export const getAppUserMenu = (customerNumber, buildingNumber) => {
  return Http.get(`manage/UserAppSetting`, {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
    },
  });
};

/**
 * EHP 목록 가져오기
 * @param {String} customerNumber
 * @param {String} buildingNumber
 * @param {String} userZone
 */
export const getEhpList = (customerNumber, buildingNumber, userZone) => {
  return Http.get(`manage/EHPList`, {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
      userZone: userZone,
    },
  });
};

/**
 * EHP에 달려있는 TAG목록 가져오기
 * @param {String} customerNumber
 * @param {String} buildingNumber
 * @param {String} userZone
 */
export const getTargetTagList = (customerNumber, buildingNumber, userZone) => {
  return Http.get(`/manage/GetUserZoneTagList`, {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
      userZone: userZone,
    },
  });
};

/**
 * Customer List 가져오기
 */

export const getCustomerInfo = () => {
  return Http.get('/manage/CustomerInfo');
};

/*
 * 관리자 EHP 제어 리스트 가져오기
 * @param {String} customerNumber
 * @param {String} buildingNumber
 */
export const getEHPInit = (customerNumber, buildingNumber) => {
  return Http.get('/manage/EHPInit', {
    params: {
      customerNumber: customerNumber,
      buildingNumber: buildingNumber,
    },
  });
};
